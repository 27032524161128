import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useGetMethods } from 'widgets/methods/api/useGetMethods';
import { SideNav } from 'widgets/side-nav';
import { Header } from '../../../widgets/header';

interface IProps extends PropsWithChildren {
  bankName?: string | undefined;
  isSingleMethod?: boolean;
}

export const Layout: FC<IProps> = ({ bankName, isSingleMethod, children }) => {
  const { isMethodsLoading, methods, isMethodsError } = useGetMethods();

  return (
    <>
      <Box
        display='grid'
        gridTemplateColumns='228px 1fr'
        gridTemplateRows='84px 140px calc(100vh - 266px)'
        gap={4}
        sx={{
          maxHeight: '100vh',
          height: isSingleMethod ? 'calc(100vh - 24px)' : '100vh',
        }}
      >
        <SideNav methods={methods}></SideNav>
        <Header bankName={bankName}></Header>
        {children}
      </Box>
    </>
  );
};
