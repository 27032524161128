import { useQuery } from '@tanstack/react-query';
import { TUsers } from 'shared/services/types/users.types';
import { UsersService } from 'shared/services/users.service';

export const useGetUsers = (data: TUsers.GetUsers) => {
  const {
    isLoading: isUsersLoading,
    data: users,
    isError: isUsersError,
  } = useQuery({
    queryFn: () => UsersService.getUsers(data),
    queryKey: ['get users'],
    retry: 0,
    select: ({ data }) => data,
  });

  return { isUsersLoading, users, isUsersError };
};
