import { Add } from '@mui/icons-material';
import { Box, Button, Divider, Grid, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TUsers } from 'shared/services/types/users.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { ModalTemplate } from 'shared/ui/modal-template';
import { addMessageToast } from 'shared/utils/addMessageToast';
import { useCreateAccount } from '../api/useCreateAccount';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  methodID: number;
}

export const AddAccountModal: FC<IProps> = ({ isOpen, setIsOpen, methodID }) => {
  const [error, setError] = useState<any>(undefined);
  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;
  const { create } = useCreateAccount();

  const onSubmit = async (data: TUsers.CreateUser) => {
    try {
      await create.mutateAsync({ ...data, method_id: methodID });
      setIsOpen(false);
      methods.reset();
      addMessageToast('Account successfully created', 'success');
    } catch (error) {
      addMessageToast(error);
    }
  };

  return (
    <ModalTemplate close={setIsOpen} isOpen={isOpen} titleText={'Account adding'} hideBackdrop={false} reset={reset}>
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container direction={'column'} spacing={20} wrap={'nowrap'}>
            <Grid item>
              <TextField
                margin='normal'
                fullWidth
                required
                id='acc_login'
                label='Enter account login'
                autoComplete='acc_login'
                placeholder='Account email or phone number'
                InputLabelProps={{ shrink: true }}
                {...methods.register('user_name')}
                sx={{ m: 0, p: 0 }}
              />
            </Grid>
            <Grid item>
              <TextField
                margin='normal'
                fullWidth
                required
                id='acc_name'
                label='Enter account name'
                autoComplete='acc_name'
                placeholder='Account Name'
                InputLabelProps={{ shrink: true }}
                {...methods.register('name')}
                sx={{ my: 0, p: 0 }}
              />
            </Grid>
            <Grid item>
              <TextField
                margin='normal'
                fullWidth
                required
                id='account_id'
                label='Enter account id'
                autoComplete='acc_id'
                placeholder='Account id'
                InputLabelProps={{ shrink: true }}
                {...methods.register('account_id')}
                sx={{ my: 0, p: 0 }}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ width: 1, m: '40px 0 24px' }}></Divider>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ m: 0, borderRadius: 50, textTransform: 'none' }}
          startIcon={<Add />}
        >
          Match
        </Button>
      </FormWrapper>
    </ModalTemplate>
  );
};
