import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import clsx from 'clsx';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import useScrollPercentage from 'shared/hooks/useScrollPercentage';
import { TablePagination } from 'shared/ui/pagination';
import styles from './table-layout.module.sass';

interface IProps extends PropsWithChildren {
  rows: any;
  columns: any;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  full?: boolean;
  small?: boolean;
  total: number;
}

export const TableLayout: FC<IProps> = ({
  rows,
  columns,
  children,
  full,
  small,
  pageSize,
  setPageSize,
  page,
  setPage,
  total,
}) => {
  const { handleScroll, handleThumbDrag, scrollbarRef, customScrollRef, customScrollThumbsRef } = useScrollPercentage();

  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    if (Array.isArray(children) && children?.length > 0) {
      setShowScroll(true);
    }
  }, [children]);

  return (
    <>
      <Box
        sx={{
          flex: '1 1',
          scrollbarColor: '#D9E2FF #E9ECF5',
          overflow: 'auto',
          pr: '8px',
          '&.MuiBox-root': {
            maxWidth: 'calc(100% + 16px)',
            width: 'calc(100% + 16px)',
          },
        }}
      >
        <TableContainer
          className={clsx(small && 'table__small', full && 'table__full')}
          sx={{
            height: 1,
            boxShadow: 'none',
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            mr: '-25px',
            overflow: 'hidden',
            '::-webkit-scrollbar': {
              display: 'none',
              width: '0',
              background: 'transparent',
              scrollbarColor: 'transparent transparent',
            },
          }}
          component={Paper}
        >
          <Scrollbars
            ref={scrollbarRef}
            style={{
              width: 'calc(100% - 18px)',
              borderRadius: '16px',
              border: '1px solid #BDC1D2',
              height: 'calc(100% - .4vh)',
              scrollbarColor: 'transparent transparent',
            }}
            renderTrackVertical={() => {
              return <Box sx={{ display: 'none' }}></Box>;
            }}
            renderThumbVertical={() => {
              return <Box sx={{ display: 'none' }}></Box>;
            }}
            onScroll={handleScroll}
          >
            <Table
              className={styles.table}
              stickyHeader
              sx={{
                minWidth: 0,
                borderBottom: 'none',
                '::-webkit-scrollbar': {
                  display: 'none',
                  width: '0',
                  background: 'transparent',
                },
              }}
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: '#D9E2FF', borderRadius: '16px 16px' }}>
                  {columns.map((column: any, id: any) => (
                    <TableCell
                      key={id}
                      align='left'
                      sx={{
                        color: '#79747E',
                        whiteSpace: 'nowrap',
                        fontSize: 16,
                        lineHeight: '24px',
                        fontWeight: '500',
                      }}
                    >
                      {column.headerName === 'more' || (column.headerName === 'Status' && small)
                        ? ''
                        : column.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  minHeight: 1,
                  mt: '54px',
                  '.MuiTableCell-root': {
                    fontSize: 16,
                    lineHeight: '24px',
                  },
                }}
              >
                {children}
              </TableBody>
            </Table>
          </Scrollbars>
          {showScroll ? (
            <Box ref={customScrollRef} style={{ width: '8px', background: '#F3F6FE', position: 'relative' }}>
              <Box
                ref={customScrollThumbsRef}
                style={{
                  width: '100%',
                  background: '#D9E2FF',
                  borderRadius: '10px',
                  position: 'absolute',
                  cursor: 'pointer',
                  top: '0',
                  height: '30%', // Initial height
                }}
                onMouseDown={handleThumbDrag}
              ></Box>
            </Box>
          ) : (
            ''
          )}
        </TableContainer>
      </Box>
      <TablePagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        page={page}
        setPage={setPage}
        total={total}
      ></TablePagination>
    </>
  );
};
