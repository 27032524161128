import { Box, Button, FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { CloseIcon } from 'shared/assets/icons';

interface IProps extends PropsWithChildren {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  labelInner: string;
}

export const FilterItem: FC<IProps> = ({ value, children, setValue, labelInner, placeholder }) => {
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  return (
    <FormControl fullWidth sx={{ minWidth: '170px' }}>
      <InputLabel shrink sx={{ backgroundColor: '#fff' }} id='account-number'>
        {labelInner}
      </InputLabel>
      <Select
        labelId='account-number'
        id='account-number'
        value={value}
        label='Account'
        style={!value ? { color: '#90939B' } : {}}
        displayEmpty
        renderValue={(selected) => {
          if (selected === '') {
            return <>{placeholder}</>;
          } else {
            if (children instanceof Array) {
              return children?.map((item: any) => (item.props.value === value ? item.props.children : ''));
            }
          }
        }}
        onChange={handleChange}
      >
        {children}
      </Select>
      <Button
        sx={{
          position: 'absolute',
          top: '50%',
          right: 30,
          transform: 'translate(0%, -50%)',
          width: 27,
          height: 27,
          cursor: 'pointer',
          minWidth: 27,
          p: 0,
        }}
        style={!value ? { display: 'none' } : {}}
        variant='text'
        type='button'
        onClick={() => {
          setValue('');
        }}
      >
        <CloseIcon />
      </Button>
    </FormControl>
  );
};
