import { Add } from '@mui/icons-material';
import { Box, Button, Divider, Grid, TextField } from '@mui/material';
import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TMethods } from 'shared/services/types/methods.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { ModalTemplate } from 'shared/ui/modal-template';
import { addMessageToast } from 'shared/utils/addMessageToast';
import { useCreateMethod } from '../api/useCreateMethod';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddMethodModal: FC<IProps> = ({ setIsOpen, isOpen }) => {
  const methods = useForm({ mode: 'onBlur' });
  const { create } = useCreateMethod();
  const { reset } = methods;

  const onSubmit: SubmitHandler<TMethods.CreateMethod> = async (data) => {
    try {
      await create.mutateAsync(data);
      addMessageToast('Method successfully added', 'success');
    } catch (error) {
      addMessageToast(error);
    }
  };

  return (
    <ModalTemplate
      close={setIsOpen}
      isOpen={isOpen}
      titleText={'Add payment method'}
      hideBackdrop={false}
      reset={reset}
    >
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container direction={'column'} spacing={20} wrap={'nowrap'}>
            <Grid item>
              <TextField
                margin='normal'
                fullWidth
                required
                id='name'
                label='Method Name'
                placeholder='Enter method Name'
                InputLabelProps={{ shrink: true }}
                {...methods.register('name')}
                autoFocus
                sx={{ m: 0, p: 0 }}
              />
            </Grid>
            <Grid item>
              <TextField
                margin='normal'
                fullWidth
                required
                id='min_value'
                label='Minimal sum'
                placeholder='Enter minimal sum'
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
                {...methods.register('min_value')}
                autoFocus
                sx={{ m: 0, p: 0 }}
              />
            </Grid>
            <Grid item>
              <TextField
                margin='normal'
                fullWidth
                required
                id='decline_time'
                label='Decline time'
                placeholder='Enter decline time'
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
                {...methods.register('decline_time')}
                autoFocus
                sx={{ m: 0, p: 0 }}
              />
            </Grid>
            <Grid item>
              <TextField
                margin='normal'
                fullWidth
                required
                id='currency'
                label='Currency '
                placeholder='Enter currency for payment method'
                InputLabelProps={{ shrink: true }}
                {...methods.register('currency')}
                autoFocus
                sx={{ m: 0, p: 0 }}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ width: 1, m: '40px 0 24px' }}></Divider>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ m: 0, borderRadius: 50, textTransform: 'none' }}
          startIcon={<Add />}
        >
          Create
        </Button>
      </FormWrapper>
    </ModalTemplate>
  );
};
