import { Box, Button, TableCell, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { CloseIcon } from 'shared/assets/icons';
import { TTransaction } from 'shared/services/types/transaction.types';
import { TableLayout } from 'shared/ui/table-layout';
import { COLUMNS_T_ALL } from 'widgets/method/mock-data/MOCK_COL_T_ALL';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';

interface IProps {
  isTransactionsLoading: boolean;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  transactions?: TTransaction.TransactionResponse;
  matchTransaction: (id: number) => void;
  setAllOperations: React.Dispatch<React.SetStateAction<String | null>>;
  isTransactionsFetching: boolean;
}

export const MethodTransaction: FC<IProps> = ({
  isTransactionsLoading,
  pageSize,
  setPageSize,
  page,
  setPage,
  transactions,
  matchTransaction,
  setAllOperations,
  isTransactionsFetching,
}) => {
  return (
    <>
      <Box
        px={12}
        gridColumn={'1 / 3'}
        sx={{
          bgcolor: '#fff',
          borderRadius: 4,
          display: 'grid',
          gridTemplateRows: '72px auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'nowrap',
            gap: '0 24px',
          }}
        >
          <Typography variant='h3' sx={{ flexGrow: 1, fontSize: 26, lineHeight: '36px' }}>
            All transactions
          </Typography>
          <Button
            variant='outlined'
            onClick={() => {
              setAllOperations(null);
            }}
            sx={{
              '.MuiButton-startIcon': {
                width: '24px',
                minWidth: '24px',
                svg: {
                  width: '24px',
                },
              },
              p: '7px 24px',
              maxWidth: '100px',
            }}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            gridRow: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            overflow: 'hidden',
            mr: '-25px',
            pr: '25px',
          }}
        >
          <TableLayout
            pageSize={pageSize}
            setPageSize={setPageSize}
            page={page}
            setPage={setPage}
            full
            rows={!isTransactionsLoading ? transactions!.items : []}
            columns={COLUMNS_T_ALL}
            total={!isTransactionsLoading ? transactions!.total : 0}
          >
            {isTransactionsLoading ? (
              <CircularProgressCenter />
            ) : (
              <>
                {transactions!.items.map((row: any) => (
                  <TableRow key={row.name}>
                    <TableCell component='th' scope='row'>
                      {row.id}
                    </TableCell>
                    <TableCell align='left'>{row.account_id}</TableCell>
                    <TableCell align='left'> {row.subagent_id}</TableCell>
                    <TableCell align='left'>{row.account_name}</TableCell>
                    <TableCell align='left'>{row.remarks}</TableCell>
                    <TableCell align='left'>{row.income}</TableCell>
                    <TableCell align='left'>{row.balance}</TableCell>
                    <TableCell align='left'>{format(row.timestamp, 'dd/MM/yyyy HH:mm')}</TableCell>
                  </TableRow>
                ))}
                {isTransactionsFetching && <CircularProgressCenter />}
              </>
            )}
          </TableLayout>
        </Box>
      </Box>
    </>
  );
};
