import { Box, Divider, Modal, Paper, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { CloseIcon } from 'shared/assets/icons';
import styles from './modal-template.module.sass';
import useOutsideClick from 'shared/hooks/useOutsideClick';

interface IProps extends PropsWithChildren {
  isOpen: boolean;
  titleText: string;
  subtitleText?: string;
  subButton?: boolean;
  hideBackdrop?: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  hideCloseIcon?: boolean;
  isDark?: boolean;
  reset: () => void;
}

export const ModalTemplate: FC<IProps> = ({
  isOpen,
  titleText,
  subtitleText,
  children,
  subButton,
  hideBackdrop,
  close,
  hideCloseIcon,
  isDark,
  reset,
}) => {
  const { ref } = useOutsideClick(false, () => {
    reset();
    close(false);
  });

  return (
    <Modal
      open={isOpen}
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      hideBackdrop={hideBackdrop}
      className={styles.container && isDark ? styles.isDark : ''}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Paper sx={{ borderRadius: 4, width: 384, position: 'relative' }}>
        <Box ref={ref}>
          <Box ref={ref}>
            <Box
              sx={{
                display: (hideCloseIcon && 'none') || 'block',
                position: 'absolute',
                top: '16px',
                right: '17px',
                width: 25,
                height: 25,
                color: '#90939B',
                cursor: 'pointer',
              }}
              onClick={() => {
                reset();
                close(false);
              }}
            >
              <CloseIcon />
            </Box>
            <Box sx={{ p: 12, borderRadius: 4 }}>
              <Typography component='h1' className={styles.title} sx={{ textAlign: 'center' }}>
                {titleText}
              </Typography>
              <Typography
                sx={{ mt: 4, textAlign: 'center', color: 'text.secondary', fontSize: '14px', lineHeight: '20px' }}
              >
                {subtitleText}
              </Typography>
            </Box>
            <Divider></Divider>
            {children}
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};
