import { GridColDef } from '@mui/x-data-grid';
import { ROWS } from './MOCK_ROW';

export const COLUMNS_A_ALL: GridColDef<(typeof ROWS)[number]>[] = [
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'account_id',
    headerName: 'Account Number',
  },
  {
    field: 'subagent_id',
    headerName: 'Subagent',
  },
  {
    field: 'account_name',
    headerName: 'Account Name',
  },
  {
    field: 'remarks',
    headerName: 'Remarks',
  },
  {
    field: 'income',
    headerName: 'Income',
  },
  {
    field: 'status',
    headerName: 'Status',
  },
  {
    field: 'more',
    headerName: '',
  },
];
