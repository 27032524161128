import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { FC } from 'react';
import { AccessTimeIcon, CloseIcon, SuccessIcon } from 'shared/assets/icons';
import styles from './status.module.sass';

export type TStatusType = 'done' | 'in_progress' | 'received' | 'declined';

interface IProps {
  status?: TStatusType;
}

const statusMap: Record<TStatusType, { icon: React.ReactElement; text: string; color?: string }> = {
  done: { icon: <SuccessIcon />, text: 'Ready' },
  in_progress: { icon: <AccessTimeIcon />, text: 'Waiting' },
  received: { icon: <AccessTimeIcon />, text: 'Waiting' },
  declined: { icon: <CloseIcon color='#BA1A1A' />, text: 'Cancel' },
};

export const Status: FC<IProps> = ({ status }) => {
  const { icon, text, color } = statusMap?.[status!] || {};

  return (
    <Box
      className={clsx(
        styles.wrapper,
        status === 'done' && styles.active,
        (status === 'in_progress' || status === 'received') && styles.waiting,
        status === 'declined' && styles.cancel,
        'status__wrapper'
      )}
      sx={{
        borderRadius: 2,
      }}
    >
      {icon}
      <Typography>{text}</Typography>
    </Box>
  );
};