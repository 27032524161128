import Cookies from 'js-cookie'
import { TTokens } from '../types/common.types'

export const saveTokensStorage = (data: TTokens) => {
	localStorage.setItem('access', data.access)
	localStorage.setItem('refresh', data.refresh || '')
	window.dispatchEvent(new Event('storage'))
}

export const removeTokensStorage = () => {
  localStorage.removeItem('access');
  localStorage.removeItem('refresh');
  localStorage.removeItem('user');
  Cookies.remove('token');
  window.dispatchEvent(new Event('storage'));
};
