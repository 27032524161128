import { Box, Button, Divider, Grid, TextField } from '@mui/material';
import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { DownloadIcon } from 'shared/assets/icons';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { ModalTemplate } from 'shared/ui/modal-template';
import { addMessageToast } from 'shared/utils/addMessageToast';
import { useCreateWithdrawal } from './api/useCreateWithdrawal';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
interface FormValues {
  account_id_from: string;
  amount: number;
  account_id_to: string;
}

export const WithdrawalModal: FC<IProps> = ({ setIsOpen, isOpen }) => {
  const methods = useForm({ mode: 'onBlur' });

  const { create } = useCreateWithdrawal();
  const { reset } = methods;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await create.mutateAsync(data);
    } catch (error) {
      addMessageToast(error);
    }
  };

  return (
    <ModalTemplate close={setIsOpen} isOpen={isOpen} titleText={'Withdrawal'} hideBackdrop={false} reset={reset}>
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container direction={'column'} spacing={20} wrap={'nowrap'}>
            <Grid item>
              <TextField
                margin='normal'
                fullWidth
                required
                id='transaction_id'
                label='Choose account'
                placeholder='LoGin 123'
                InputLabelProps={{ shrink: true }}
                {...methods.register('account_id_from')}
                autoFocus
                sx={{ m: 0, p: 0 }}
              />
            </Grid>
            <Grid item>
              <TextField
                margin='normal'
                fullWidth
                required
                id='amount'
                label='Enter summ'
                placeholder='Enter summ'
                InputLabelProps={{ shrink: true }}
                {...methods.register('amount')}
                autoFocus
                sx={{ m: 0, p: 0 }}
              />
            </Grid>
            <Grid item>
              <TextField
                margin='normal'
                fullWidth
                required
                id='withdrawal_acc_id'
                label='Enter account for withdrawal '
                placeholder='Enter account for withdrawal'
                InputLabelProps={{ shrink: true }}
                {...methods.register('account_id_to')}
                autoFocus
                sx={{ m: 0, p: 0 }}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ width: 1, m: '40px 0 24px' }}></Divider>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ m: 0, borderRadius: 50, textTransform: 'none' }}
          startIcon={<DownloadIcon />}
        >
          Send
        </Button>
      </FormWrapper>
    </ModalTemplate>
  );
};
