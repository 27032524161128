import { useState, useEffect } from 'react';
import { TAuth } from 'shared/services/types/auth.types';

const useUser = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser !== null) {
      const parsedUser = JSON.parse(storedUser);
      setName(parsedUser.name);
      if (parsedUser.role === 'Администратор') {
        setIsAdmin(true);
      }
    }
  }, []);

  return { isAdmin, name };
};
export default useUser;
