import { toFormData } from 'axios'
import { axiosInstance } from 'shared/api'
import { getApplicationUrl, getMethodUrl, getTransactionUrl } from 'shared/api/config/api.config'
import { TApplications } from './types/application.types'
import { TMethods } from './types/methods.types'
import { TTransaction } from './types/transaction.types'

export const MethodsService = {
  async getMethods(pagination: { size: number; page: number } = { size: 100, page: 0 }) {
    const { size, page } = pagination;
    const response = await axiosInstance.get<TMethods.GetMethodsSuccess>(getMethodUrl('/get-methods'), {
      params: {
        size,
        page,
      },
    });

    return response;
  },

  async getTransactions(data: TTransaction.GetTransaction) {
    const response = await axiosInstance.get<TTransaction.TransactionResponse>(getTransactionUrl('/get-transactions'), {
      params: { ...data },
    });

    return response;
  },

  async getApplications(data: TApplications.GetApplication) {
    const response = await axiosInstance.get<TApplications.ApplicationResponse>(
      getApplicationUrl('/get-applications'),
      {
        params: { ...data },
      }
    );

    return response;
  },

  async changeApplication(data: TApplications.ChangeApplication) {
    const response = await axiosInstance.post<TApplications.ApplicationResponse>(
      getApplicationUrl('/change-application'),
      toFormData(data)
    );

    return response;
  },

  async createMethod(data: TMethods.CreateMethod) {
    const response = await axiosInstance.post<TMethods.Method>(
      getMethodUrl(''),
      toFormData(data)
    );
    return response;
  },
};
