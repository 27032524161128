import { CheckCircleRounded, RadioButtonUncheckedRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useActionAuth } from 'features/auth/api/useActionAuth';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TAuth } from 'shared/services/types/auth.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { ModalTemplate } from 'shared/ui/modal-template';
import styles from './login.module.sass';

interface IValidForm {
  email?: boolean;
  password?: boolean;
}

export const LoginModal = () => {
  const [resetPass, setResetPass] = useState(false);
  const [submited, setSubmited] = useState<IValidForm>({
    email: false,
    password: false,
  });
  const [error, setError] = useState<any>(undefined);
  const methods = useForm({ mode: 'onSubmit' });
  const { reset } = methods;
  const { loginUser } = useActionAuth();
  const navigate = useNavigate();

  const onSubmit = async (data: FieldValues) => {
    setSubmited({ email: true, password: true });
    try {
      const loginData = data as TAuth.Login;
      await loginUser.mutateAsync(loginData);
      navigate('/');
    } catch (error) {
      setError(error);
    }
  };

  return (
    <ModalTemplate
      close={() => {}}
      isDark
      hideCloseIcon
      isOpen
      titleText={resetPass ? 'Reset password' : 'Sing in to CashDash'}
      hideBackdrop={true}
      subtitleText={resetPass ? 'Enter your email to reset your password' : 'Enter your login and password to sign in'}
      reset={reset}
    >
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container direction={'column'} spacing={20} wrap={'nowrap'} position={'relative'}>
            {!resetPass && (
              <>
                <Grid item>
                  <TextField
                    margin='normal'
                    fullWidth
                    required
                    id='email'
                    label='Login'
                    autoComplete='email'
                    error={methods.formState.errors?.email || error}
                    placeholder='Your login'
                    InputLabelProps={{ shrink: true }}
                    {...methods.register('email', {
                      required: true,
                      minLength: { value: 1, message: 'Wrong login' },
                    })}
                    onChange={() => {}}
                    autoFocus
                    className={clsx(
                      submited.email && !methods.formState.errors?.email && !error && [styles.success, 'success']
                    )}
                    sx={{ m: 0, p: 0 }}
                  />
                </Grid>
                <Grid item sx={{ pt: '24px !important' }}>
                  <TextField
                    margin='normal'
                    fullWidth
                    required
                    type='password'
                    id='password'
                    error={methods.formState.errors?.password || error}
                    label='Password'
                    autoComplete='current-password'
                    placeholder='***'
                    InputLabelProps={{ shrink: true }}
                    {...methods.register('password', { minLength: { value: 4, message: 'Too short password' } })}
                    className={clsx(
                      submited.password && !methods.formState.errors?.password && !error && [styles.success, 'success']
                    )}
                  />
                </Grid>
                <FormHelperText
                  color='error'
                  sx={{
                    left: 'calc(50% + 20px) !important',
                    transform: 'translateX(-50%)',
                  }}
                >
                  {(error && error.response.data.detail) ||
                    (methods.formState.errors && methods.formState.errors.password?.message)}
                </FormHelperText>
              </>
            )}
            {resetPass && (
              <>
                <Grid item>
                  <TextField
                    margin='normal'
                    fullWidth
                    required
                    id='email_reset'
                    label='Your email'
                    error={error}
                    {...methods.register('email_reset')}
                    autoComplete='email'
                    placeholder='Your login'
                    InputLabelProps={{ shrink: true }}
                    autoFocus
                    sx={{ m: 0, p: 0, '&:invalid': { background: 'red' } }}
                  />
                </Grid>
                <FormHelperText
                  color='error'
                  sx={{
                    left: 'calc(50% + 20px) !important',
                    transform: 'translateX(-50%)',
                  }}
                >
                  {(error && error.response.data.detail) ||
                    (methods.formState.errors && methods.formState.errors.email_reset?.message)}
                </FormHelperText>
              </>
            )}
          </Grid>
          {resetPass ? <Divider sx={{ width: 1, my: 12 }}></Divider> : <Divider sx={{ width: 1, pt: 14 }}></Divider>}
          {!resetPass && (
            <>
              <Grid container columns={2} wrap={'nowrap'} sx={{ justifyContent: 'space-between', py: 8 }}>
                <Grid item>
                  <FormControlLabel
                    sx={{
                      marginLeft: '-4px',
                      '.MuiTypography-root': {
                        fontSize: 14,
                        lineHeight: '20px',
                        color: '#90939B',
                      },
                      '.MuiSvgIcon-fontSizeMedium': { color: '#BDC1D2', fontSize: 22 },
                      '.Mui-checked .MuiSvgIcon-fontSizeMedium': {
                        color: '#1EBD8D',
                      },
                      '.Mui-checked + .MuiTypography-root': {
                        color: '#44464F',
                      },
                    }}
                    control={
                      <Checkbox
                        value='true'
                        color='success'
                        sx={{ p: 1 }}
                        {...methods.register('checkbox_remeber')}
                        icon={<RadioButtonUncheckedRounded />}
                        checkedIcon={<CheckCircleRounded />}
                      />
                    }
                    label='Remember me'
                  />
                </Grid>
                <Grid item>
                  <Link
                    component='button'
                    type='button'
                    variant='body2'
                    sx={{ height: 1 }}
                    onClick={() => {
                      setError(null);
                      methods.reset({});
                      setResetPass(true);
                    }}
                  >
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ m: 0, borderRadius: 50, textTransform: 'none' }}
          onClick={() => {
            setSubmited({ email: true, password: true });
          }}
        >
          {resetPass ? 'Reset password' : 'Sign In'}
        </Button>
        {resetPass && (
          <Box display='flex' alignItems='center'>
            <Typography
              onClick={() => {
                setError(null);
                methods.reset({});
                setResetPass(false);
                setSubmited({ email: false, password: false });
              }}
              className={resetPass && styles.subButton}
            >
              Get Back
            </Typography>
          </Box>
        )}
      </FormWrapper>
    </ModalTemplate>
  );
};
