import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { AddAccountModal } from 'features/add-account';
import { AddMethodModal } from 'features/add-method';
import { WithdrawalModal } from 'features/withdrawal';
import { FC, PropsWithChildren, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DownloadIcon } from 'shared/assets/icons';
import useUser from 'shared/hooks/useUser';

interface IProps extends PropsWithChildren {
  bankName?: string | undefined;
}

export const Header: FC<IProps> = ({ bankName }) => {
  const [isOpenWithdrawal, setIsOpenWithdrawal] = useState(false);
  const [isOpenAddMethod, setIsOpenAddMethod] = useState(false);
  const [modalAccountAdd, setModalAccountAdd] = useState(false);

  const { id: useParamsMethodID } = useParams();
  const methodID = parseInt(useParamsMethodID!);

  const { isAdmin } = useUser();

  return (
    <Box
      gridColumn='2/4'
      gridRow='1'
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap',
        gap: 12,
        py: 10,
        px: 12,
        borderRadius: 4,
        bgcolor: '#fff',
      }}
    >
      <Typography variant='h3' color='primary' sx={{ flexGrow: 1, fontSize: 28, lineHeight: '36px' }}>
        {bankName ? 'Methods: ' + bankName : 'Methods'}
      </Typography>
      {isAdmin && (
        <Button
          variant='contained'
          sx={{ minWidth: 188, py: 5 }}
          startIcon={<Add />}
          onClick={() => {
            setIsOpenAddMethod(true);
          }}
        >
          Add method
        </Button>
      )}
      {methodID ? (
        <Button
          variant='outlined'
          onClick={() => {
            setModalAccountAdd(true);
          }}
          sx={{ p: '10px 24px', maxWidth: 92 }}
          startIcon={<Add />}
        >
          Add account
        </Button>
      ) : (
        ''
      )}
      <Button
        variant='outlined'
        sx={{ minWidth: 188, py: 5 }}
        startIcon={<DownloadIcon />}
        onClick={() => {
          setIsOpenWithdrawal(true);
        }}
      >
        Withdrawal
      </Button>
      <AddMethodModal isOpen={isOpenAddMethod} setIsOpen={setIsOpenAddMethod}></AddMethodModal>
      <WithdrawalModal isOpen={isOpenWithdrawal} setIsOpen={setIsOpenWithdrawal}></WithdrawalModal>
      <AddAccountModal isOpen={modalAccountAdd} setIsOpen={setModalAccountAdd} methodID={methodID} />
    </Box>
  );
};
