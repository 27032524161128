import { Add } from '@mui/icons-material';
import { Box, Button, Divider, Grid, TextField } from '@mui/material';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { ModalTemplate } from 'shared/ui/modal-template';

interface IProps {
  isOpen: boolean;
  onSubmit: (data: any) => Promise<void>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  error: any;
}

export const MatchTransaction: FC<IProps> = ({ setIsOpen, isOpen, onSubmit, error }) => {
  const methods = useForm({ mode: 'onBlur' });
  const { reset } = methods;

  return (
    <ModalTemplate close={setIsOpen} isOpen={isOpen} titleText={'Match Transaction'} hideBackdrop={false} reset={reset}>
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container direction={'column'} spacing={20} wrap={'nowrap'}>
            <Grid item>
              <TextField
                margin='normal'
                fullWidth
                required
                id='transaction_id'
                label='Enter Transaction ID'
                placeholder='Enter Transaction ID'
                InputLabelProps={{ shrink: true }}
                {...methods.register('transaction_id')}
                autoFocus
                sx={{ m: 0, p: 0 }}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ width: 1, m: '24px 0' }}></Divider>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ m: 0, borderRadius: 50, textTransform: 'none' }}
          startIcon={<Add />}
          onSubmit={onSubmit}
        >
          Match
        </Button>
      </FormWrapper>
    </ModalTemplate>
  );
};
