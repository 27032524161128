import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

import { API_URL } from 'shared/api/config/api.config';
import { removeTokensStorage } from 'shared/services/auth/auth.helper';
import { AuthService } from 'shared/services/auth/auth.service';

const axiosInstance = axios.create({
  baseURL: API_URL,
});

const interceptorsRequestSuccess = (config: InternalAxiosRequestConfig<any>) => {
  const accessToken = localStorage.getItem('access') || Cookies.get('token');

  if (config.headers && accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

  return config;
};

axiosInstance.interceptors.request.use(interceptorsRequestSuccess);

const interceptorsResponseError = async (error: AxiosError<any>) => {
  const originalRequest = { ...error.config, _isRetry: false };

  if ([401].includes(error.response!.status) && error.config && !originalRequest._isRetry) {
    originalRequest._isRetry = true;
    try {
      await AuthService.refreshToken();

      return axiosInstance.request(originalRequest);
    } catch (error) {
      removeTokensStorage();
    }
  }

  throw error;
};

axiosInstance.interceptors.response.use(async (config) => {
  return config;
}, interceptorsResponseError);

export default axiosInstance;
