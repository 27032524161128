import { toFormData } from 'axios'
import { axiosInstance } from 'shared/api'
import { getUserUrl } from 'shared/api/config/api.config'
import { TUsers } from './types/users.types'

export const UsersService = {
	async getUsers(data: TUsers.GetUsers) {
		const response = await axiosInstance.get<TUsers.GetUsersSuccess>(
			getUserUrl('/get-accounts'),
			{
				params: { ...data },
			}
		)

		return response
	},

	async createUser(data: TUsers.CreateUser) {
		const response = await axiosInstance.post<TUsers.CreateUser>(
			getUserUrl('/create-account'),
			toFormData(data)
		)

		return response
	}
}
