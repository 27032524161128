import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout } from 'shared/ui/layout';

interface IProps extends PropsWithChildren {}

///@ts-ignore
export const ProtectedRoute: FC<IProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem('access'));

  useEffect(() => {
    if (!token) {
      navigate('/login');
    } else if (token && location.pathname === '/login') {
      navigate('/');
    }
  }, [token, location.pathname]);

  const watchStorage = () => {
    setToken(localStorage.getItem('access'));
  };

  useEffect(() => {
    window.addEventListener('storage', watchStorage);
    return () => {
      window.removeEventListener('storage', watchStorage);
    };
  }, []);

  if (children) return <>{children}</>;

  return (
    <>
      <Layout>Error</Layout>
    </>
  );
};
