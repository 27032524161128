import { Login } from 'pages/login';
import MethodPage from 'pages/method';
import MethodsPage from 'pages/methods';
import { createBrowserRouter } from 'react-router-dom';
import { ProtectedRoute } from './protected-route';
import AppLayout from 'shared/ui/app-layout';

export const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute>
            <MethodsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/login',
        element: (
          <ProtectedRoute>
            <Login />
          </ProtectedRoute>
        ),
      },
      {
        path: '/method',
        element: (
          <ProtectedRoute>
            <MethodPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '/method/:id',
        element: (
          <ProtectedRoute>
            <MethodPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);
