import { PersonOutlineOutlined, Settings } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { AppsRounded, ExpandMore, LogOut, Logo, MethodsIcon, SidenavMenuIcon } from 'shared/assets/icons';
import useUser from 'shared/hooks/useUser';
import { AuthService } from 'shared/services/auth/auth.service';
import { TMethods } from 'shared/services/types/methods.types';

interface IProps {
  methods?: TMethods.Method[];
}

export const SideNav: FC<IProps> = ({ methods }) => {
  const { id: useParamsMethodID } = useParams();
  const methodID = parseInt(useParamsMethodID!);
  const [open, setOpen] = useState(false);
  const [openMethods, setOpenMethods] = useOutletContext<[boolean, (open: boolean) => void]>();
  const { name, isAdmin } = useUser();
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickMethods = () => {
    setOpenMethods(!openMethods);
  };

  return (
    <>
      <Box
        position={'fixed'}
        height={'calc(100vh - 24px)'}
        width={'224px'}
        gridColumn={1}
        gridRow='1 / 4'
        sx={{
          bgcolor: 'primary.main',
          color: '#fff',
          borderRadius: 4,
          marginRight: 2,
          overflow: 'hidden',
          zIndex: '1000',
          '& .MuiDivider-root': { borderColor: '#A4B1EA' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'stretch',
        }}
      >
        <Grid
          container
          sx={{
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <Grid
            item
            sx={{
              maxWidth: 1,
            }}
          >
            <Box sx={{ p: 12 }}>
              <Logo />
            </Box>
          </Grid>

          <Grid
            item
            sx={{
              maxWidth: 1,
            }}
          >
            <Divider></Divider>
          </Grid>
          <Grid
            item
            sx={{
              maxWidth: 1,
            }}
          >
            <Box>
              <Box sx={{ flexDirection: 'row', justifyContent: 'space-between', p: '12px 24px', m: 4 }}>
                <Box display='grid' gridTemplateColumns='28px 84px' gridTemplateRows='23px 16px' gap={'0px 8px'}>
                  <Box gridColumn='1' gridRow='1/3' sx={{ alignSelf: 'center' }}>
                    <Badge color='success' variant='dot'>
                      <Avatar
                        alt={`${name}`}
                        src='../../shared/assets/icons/profile.png'
                        sx={{ width: 28, height: 28 }}
                      />
                    </Badge>
                  </Box>
                  <Box gridColumn='2' gridRow='1'>
                    <ListItemText sx={{ m: 0 }} primary={name} />
                  </Box>

                  <Box gridColumn='2' gridRow='2'>
                    <ListItemText
                      sx={{ m: 0, '.MuiTypography-root': { fontSize: 12, lineHeight: '16px' } }}
                      secondary={isAdmin ? 'Administrator' : 'User'}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            sx={{
              maxWidth: 1,
            }}
          >
            <Divider></Divider>
          </Grid>

          <Grid
            item
            sx={{
              maxWidth: 1,
              pt: 6,
            }}
          >
            <ListItemButton sx={{ width: 0.89, p: '16px 12px', m: '0 auto' }}>
              <ListItemIcon sx={{ color: '#fff', minWidth: '33px' }}>
                <AppsRounded />
              </ListItemIcon>
              <ListItemText primary='Dashboard' />
            </ListItemButton>
            <List
              sx={{
                width: '100%',
                maxWidth: 0.89,
                mx: 'auto',
                ...(openMethods && { backgroundColor: 'rgba(0, 41, 111, 0.5)' }),
                padding: 0,
                borderRadius: '28px',
                display: 'grid',
                gap: '6px',
              }}
              component='nav'
            >
              <ListItemButton
                sx={{
                  p: '19px 15px',
                  borderRadius: '100px',
                  ...(openMethods && { background: '#001944' }),
                }}
                onClick={handleClickMethods}
              >
                <ListItemIcon sx={{ minWidth: '33px' }}>
                  <MethodsIcon />
                </ListItemIcon>
                <ListItemText onClick={() => navigate('/')} sx={{ m: 0 }} primary='Methods' />
                {openMethods ? <ExpandMore /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openMethods} timeout='auto' unmountOnExit>
                <List component='div' sx={{ display: 'grid', gap: '4px', paddingBottom: 4 }} disablePadding>
                  {!methods ? (
                    <Box
                      sx={{
                        minWidth: 1,
                        minHeight: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      {methods!.map((method: any, id: any) => (
                        <ListItemButton
                          onClick={() => {
                            navigate('/method/' + method.id);
                          }}
                          sx={{
                            pl: 4,
                            borderRadius: '100px',
                            p: '16px 24px 16px 36px',
                            mx: 6,
                            position: 'relative',
                            '&:hover': { background: '#00296F' },
                            ...(method.id === methodID && {
                              background: '#00296F',
                              '&:after': {
                                content: `''`,
                                position: 'absolute',
                                top: '50%',
                                left: '16px',
                                borderRadius: '50%',
                                width: '8px',
                                height: '8px',
                                background: '#185AC2',
                                transform: 'translate(0%, -50%)',
                              },
                            }),
                          }}
                          key={id}
                        >
                          <ListItemText sx={{ m: 0 }} primary={method.name} />
                        </ListItemButton>
                      ))}
                    </>
                  )}
                </List>
              </Collapse>
            </List>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            maxWidth: 1,
          }}
        >
          <Divider></Divider>
        </Grid>
        <Grid item sx={{ alignSelf: 'stretch' }}>
          <List component='div' sx={{ px: 6 }}>
            <ListItemButton sx={{ p: '16px 12px' }}>
              <ListItemIcon sx={{ color: '#fff', minWidth: 36 }}>
                <PersonOutlineOutlined />
              </ListItemIcon>
              <ListItemText primary='Profile' />
              <Badge
                color='success'
                variant='dot'
                sx={{
                  '.MuiBadge-badge': {
                    minWidth: 12,
                    height: 12,
                    transform: 'scale(1) translate(0%, -50%)',
                  },
                }}
              ></Badge>
            </ListItemButton>
            <ListItemButton sx={{ p: '16px 12px' }}>
              <ListItemIcon sx={{ color: '#fff', minWidth: 36 }}>
                <Settings />
              </ListItemIcon>
              <ListItemText primary='Settings' />
            </ListItemButton>
            <ListItemButton sx={{ p: '16px 12px' }}>
              <ListItemIcon sx={{ color: '#fff', minWidth: 36 }}>
                <LogOut />
              </ListItemIcon>
              <ListItemText onClick={AuthService.logout} primary='Log out' />
            </ListItemButton>
          </List>
        </Grid>
        <Grid
          item
          sx={{
            maxWidth: 1,
          }}
        >
          <Divider></Divider>
        </Grid>
        <Grid
          item
          sx={{
            maxWidth: 1,
          }}
        >
          <Box>
            <Grid container sx={{ justifyContent: 'space-between', p: 6 }}>
              <Grid item sx={{ alignSelf: 'center' }}>
                <Typography variant='body1' sx={{ fontSize: 14, lineHeight: '20px' }}>
                  Menu
                </Typography>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <SidenavMenuIcon />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </>
  );
};
