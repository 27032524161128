import { Box, CircularProgress } from '@mui/material';
import { MethodsItem } from 'features/methods-item';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'shared/ui/layout';
import { useGetMethods } from '../api/useGetMethods';
import { useGetApplications } from 'features/applications';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';

export const Methods = () => {
  const navigate = useNavigate();

  const { isMethodsLoading, methods, isMethodsError } = useGetMethods();
  return (
    <Layout>
      <Box
        gridRow={'2/4'}
        gridColumn={'2/4'}
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridAutoRows: 'minmax(350px, calc(50% - 4px))',
          gap: 4,
          maxHeight: 1,
          overflow: 'auto',
        }}
      >
        {isMethodsLoading || isMethodsError ? (
          <CircularProgressCenter />
        ) : (
          methods?.map((method, id) => (
            <MethodsItem methodsInfo={{ name: method.name, id: method.id }} key={id}></MethodsItem>
          ))
        )}
      </Box>
    </Layout>
  );
};
