import { MoreHorizRounded } from '@mui/icons-material';
import { Box, Button, Grid, MenuItem, SelectChangeEvent, TableCell, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { MethodApplication, useGetApplications } from 'features/applications';
import { MatchTransaction } from 'features/match-transaction';
import { useChangeApplication } from 'features/match-transaction/api/useMatchTransaction';
import { MethodTransaction, useGetTransactions } from 'features/transactions';
import { useGetUsers } from 'features/users/api/useGetUsers';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { OpenArrowIcon, SyncIcon } from 'shared/assets/icons';
import useCurrentUrl from 'shared/hooks/useCurrentUrl';
import useRefetchUsers from 'shared/hooks/useRefetchUsers';
import { TApplications } from 'shared/services/types/application.types';
import { TTransaction } from 'shared/services/types/transaction.types';
import { TUsers } from 'shared/services/types/users.types';
import { FilterItem } from 'shared/ui/filter-item';
import { Layout } from 'shared/ui/layout';
import { Status, TStatusType } from 'shared/ui/status';
import { TableLayout } from 'shared/ui/table-layout';
import { addMessageToast } from 'shared/utils/addMessageToast';
import { useGetMethods } from 'widgets/methods/api/useGetMethods';
import { COLUMNS_A } from './mock-data/MOCK_COL_A';
import { COLUMNS_T } from './mock-data/MOCK_COL_T';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';

export const Method = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const methods = useForm({ mode: 'onBlur' });
  const navigate = useNavigate();
  const { id: useParamsMethodID } = useParams();
  const { isMethodsLoading, methods: methodsData, isMethodsError } = useGetMethods();
  const [bankName, setBankName] = useState('');
  const [error, setError] = useState<any>(undefined);

  const methodID = parseInt(useParamsMethodID!);

  const [allOperations, setAllOperations] = useState<String | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [matchApp, setMatchApp] = useState<number>(0);

  useEffect(() => {
    setIsOpen(false);
    setAllOperations(null);
    const methodLabel = methodsData?.find((method) => method.id === methodID)?.name || '';
    setBankName(methodLabel);
  }, [useParamsMethodID, methodsData]);

  const [pageSizeA, setPageSizeA] = useState(20);
  const [pageA, setPageA] = useState(0);

  const [pageSizeT, setPageSizeT] = useState(20);
  const [pageT, setPageT] = useState(0);

  const [accountNumber, setAccountNumber] = useState('');
  const [status, setStatus] = useState('');

  const { match } = useChangeApplication();

  const defaultFilterT = {
    size: pageSizeT,
    page: pageT,
    order_by: 'id',
    method: methodID,
    ...(methodID === 18
      ? {
          subagent: accountNumber ? +accountNumber : null,
        }
      : {
          account_id: accountNumber,
        }),
    status: status ? status : undefined,
    is_desc_sort: true,
  } as TTransaction.GetTransaction;

  const defaultFilterA = {
    size: pageSizeA,
    page: pageA,
    order_by: 'id',
    method: methodID,
    ...(methodID === 18
      ? {
          subagent: accountNumber ? +accountNumber : null,
        }
      : {
          account_id: accountNumber,
        }),
    status: status,
    is_desc_sort: true,
  } as TApplications.GetApplication;

  const defaultFilterU = {
    size: 1000,
    page: 0,
    order_by: 'method_id',
    method_id: methodID,
    is_desc_sort: true,
  } as TUsers.GetUsers;

  const onSubmit = async (data: any) => {
    try {
      await match.mutateAsync({
        transaction_id: data.transaction_id,
        application_id: matchApp,
      });
      addMessageToast('Matched successfully', 'success');
      setIsOpen(false);
    } catch (error) {
      addMessageToast(error);
    }
  };

  const matchTransaction = (id: number) => {
    setIsOpen(true);
    setMatchApp(id);
  };

  const { isTransactionsLoading, transactions, isTransactionsError, isTransactionsFetching } =
    useGetTransactions(defaultFilterT);

  const { isApplicationsLoading, applications, isApplicationsError, isApplicationsFetching } =
    useGetApplications(defaultFilterA);

  const { isUsersLoading, users, isUsersError } = useGetUsers(defaultFilterU);

  useRefetchUsers();

  const currentMethod: any = useCurrentUrl();

  useEffect(() => {
    setAccountNumber('');
  }, [currentMethod]);

  useEffect(() => {
    setPageA(0);
    setPageT(0);
  }, [accountNumber, status]);

  return (
    <>
      <Layout bankName={bankName} isSingleMethod={true}>
        <Box
          gridRow={'2'}
          display={'block'}
          gridColumn='2/4'
          sx={{ backgroundColor: '#fff', borderRadius: 4, py: 9, px: 12 }}
        >
          <Grid container sx={{ gap: 5 }}>
            <Grid item sx={{ width: 1 }}>
              <Typography variant='h6' sx={{ fontSize: 16, fontWeight: '500' }}>
                Filters
              </Typography>
            </Grid>
            {allOperations === null && (
              <>
                <Grid item sx={{ minWidth: '19%' }}>
                  <FilterItem
                    value={accountNumber}
                    setValue={setAccountNumber}
                    labelInner='Account'
                    placeholder='Choose account'
                  >
                    {!isUsersLoading &&
                      users!.items.map((user) => (
                        <MenuItem key={user.account_id.toString()} value={user.account_id.toString()}>
                          {user.account_id}
                        </MenuItem>
                      ))}
                  </FilterItem>
                </Grid>
                <Grid item sx={{ minWidth: '19%' }}>
                  <FilterItem labelInner='Status' placeholder='All' value={status} setValue={setStatus}>
                    <MenuItem value={'received'}>Pending</MenuItem>
                    <MenuItem value={'declined'}>Declined</MenuItem>
                    <MenuItem value={'done'}>Approved</MenuItem>
                  </FilterItem>
                </Grid>
              </>
            )}
            {allOperations === 'applications' && (
              <>
                <Grid item sx={{ minWidth: '19%' }}>
                  <FilterItem
                    labelInner='Account'
                    placeholder='Choose account'
                    value={accountNumber}
                    setValue={setAccountNumber}
                  >
                    {!isUsersLoading &&
                      users!.items.map((user) => (
                        <MenuItem key={user.account_id.toString()} value={user.account_id.toString()}>
                          {user.account_id}
                        </MenuItem>
                      ))}
                  </FilterItem>
                </Grid>
                <Grid item sx={{ minWidth: '19%' }}>
                  <FilterItem labelInner='Status' placeholder='All' value={status} setValue={setStatus}>
                    <MenuItem value={'received'}>Pending</MenuItem>
                    <MenuItem value={'declined'}>Declined</MenuItem>
                    <MenuItem value={'done'}>Approved</MenuItem>
                  </FilterItem>
                </Grid>
              </>
            )}
            {allOperations === 'transactions' && (
              <>
                <Grid item sx={{ minWidth: '19%' }}>
                  <FilterItem
                    labelInner='Account'
                    placeholder='Choose account'
                    value={accountNumber}
                    setValue={setAccountNumber}
                  >
                    {!isUsersLoading &&
                      users!.items.map((user) => (
                        <MenuItem key={user.account_id.toString()} value={user.account_id.toString()}>
                          {user.account_id}
                        </MenuItem>
                      ))}
                  </FilterItem>
                </Grid>
                <Grid item sx={{ minWidth: '19%' }}>
                  <FilterItem labelInner='Status' placeholder='All' value={status} setValue={setStatus}>
                    <MenuItem value={'received'}>Pending</MenuItem>
                    <MenuItem value={'declined'}>Declined</MenuItem>
                    <MenuItem value={'done'}>Approved</MenuItem>
                    <MenuItem sx={{ ml: '-5px' }} value={''}>
                      All
                    </MenuItem>
                  </FilterItem>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        <Box
          gridRow={'3'}
          gridColumn='2/4'
          sx={{
            display: 'grid',
            gridTemplateColumns: 'minmax(450px,1fr) minmax(450px, 1fr)',
            gridTemplateRows: '100%',
            gap: 4,
            overflowX: 'auto',
          }}
        >
          {allOperations === null && (
            <>
              <Box
                px={12}
                sx={{
                  bgcolor: '#fff',
                  borderRadius: 4,
                  display: 'grid',
                  gridTemplateRows: '72px auto',
                  minHeight: '350px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    gap: '0 24px',
                  }}
                >
                  <Typography variant='h3' sx={{ flexGrow: 1, fontSize: 16, lineHeight: '36px' }}>
                    Applications
                  </Typography>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      setAllOperations('applications');
                    }}
                    startIcon={<OpenArrowIcon />}
                    sx={{ p: '7px 26px', maxWidth: '100px' }}
                  >
                    Open
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    gridRow: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                    overflow: 'hidden',
                    mr: '-25px',
                    pr: '25px',
                  }}
                >
                  <TableLayout
                    pageSize={pageSizeA}
                    setPageSize={setPageSizeA}
                    page={pageA}
                    setPage={setPageA}
                    small
                    rows={!isApplicationsLoading ? applications!.items : []}
                    columns={COLUMNS_A}
                    total={!isApplicationsLoading ? applications!.total : 0}
                  >
                    {isApplicationsLoading ? (
                      <CircularProgressCenter />
                    ) : (
                      <>
                        {applications!.items.map((row) => {
                          const status = row.status as TStatusType;
                          const accountId = methodID === 18 ? row.subagent_id : row.account_id;

                          return (
                            <TableRow key={row.id}>
                              <TableCell align='left'>
                                <Status status={status} />
                              </TableCell>
                              <TableCell component='th' scope='row'>
                                {row.id}
                              </TableCell>
                              <TableCell align='left'>
                                {format(fromZonedTime(row.timestamp, 'UTC'), 'dd/MM/yyyy HH:mm:SS')}
                              </TableCell>
                              <TableCell align='left'>{row.transaction_id}</TableCell>
                              <TableCell align='left'>{accountId}</TableCell>
                              <TableCell align='left'>{row.subagent_id}</TableCell>
                              <TableCell align='left'>{row.remarks}</TableCell>
                              <TableCell align='left'>{row.amount}</TableCell>
                              <TableCell align='left' sx={{ cursor: 'pointer' }}>
                                <MoreHorizRounded
                                  onClick={() => {
                                    matchTransaction(row.id);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {isApplicationsFetching && <CircularProgressCenter />}
                      </>
                    )}
                  </TableLayout>
                </Box>
              </Box>
              <Box
                px={12}
                sx={{
                  bgcolor: '#fff',
                  borderRadius: 4,
                  display: 'grid',
                  gridTemplateRows: '72px auto',
                  minHeight: '350px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    gap: '0 24px',
                  }}
                >
                  <Typography variant='h3' sx={{ flexGrow: 1, fontSize: 16, lineHeight: '36px' }}>
                    Transactions
                  </Typography>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      setPageT(0);
                    }}
                    startIcon={<SyncIcon />}
                    sx={{ p: '7px 25px', maxWidth: '109px' }}
                  >
                    Renew
                  </Button>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      setAllOperations('transactions');
                    }}
                    startIcon={<OpenArrowIcon />}
                    sx={{ p: '7px 25px', maxWidth: '100px' }}
                  >
                    Open
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    gridRow: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                    overflow: 'hidden',
                    mr: '-25px',
                    pr: '25px',
                  }}
                >
                  <TableLayout
                    pageSize={pageSizeT}
                    setPageSize={setPageSizeT}
                    page={pageT}
                    setPage={setPageT}
                    small
                    rows={!isTransactionsLoading ? transactions?.items || [] : []}
                    columns={COLUMNS_T}
                    total={!isTransactionsLoading ? transactions?.total || 0 : 0}
                  >
                    {isTransactionsLoading ? (
                      <CircularProgressCenter />
                    ) : (
                      <>
                        {(transactions?.items || []).map((row) => {
                          const accountId = methodID === 18 ? row.subagent_id : row.account_id;
                          const status = row?.status;

                          return (
                            <TableRow key={row.id}>
                              <TableCell align='left'>
                                <Status status={status} />
                              </TableCell>
                              <TableCell component='th' scope='row'>
                                {row.id}
                              </TableCell>
                              <TableCell align='left'>
                                {format(fromZonedTime(row.timestamp, 'UTC'), 'dd/MM/yyyy HH:mm:SS')}
                              </TableCell>
                              <TableCell align='left'>{accountId}</TableCell>
                              <TableCell align='left'>{row.subagent_id}</TableCell>
                              <TableCell align='left'>{row.remarks}</TableCell>
                              <TableCell align='left'>{row.income}</TableCell>
                              <TableCell align='left'>{row.balance}</TableCell>
                              <TableCell align='left'>{row.application_id}</TableCell>
                            </TableRow>
                          );
                        })}
                        {isTransactionsFetching && <CircularProgressCenter />}
                      </>
                    )}
                  </TableLayout>
                </Box>
              </Box>
            </>
          )}
          {allOperations === 'applications' && (
            <MethodApplication
              isApplicationsLoading={isApplicationsLoading}
              pageSize={pageSizeA}
              setPageSize={setPageSizeA}
              page={pageA}
              setPage={setPageA}
              applications={applications}
              matchTransaction={matchTransaction}
              setAllOperations={setAllOperations}
              isApplicationsFetching={isApplicationsFetching}
            />
          )}
          {allOperations === 'transactions' && (
            <MethodTransaction
              isTransactionsLoading={isTransactionsLoading}
              pageSize={pageSizeT}
              setPageSize={setPageSizeT}
              page={pageT}
              setPage={setPageT}
              transactions={transactions}
              matchTransaction={matchTransaction}
              setAllOperations={setAllOperations}
              isTransactionsFetching={isTransactionsFetching}
            />
          )}
        </Box>
        <MatchTransaction onSubmit={onSubmit} isOpen={isOpen} setIsOpen={setIsOpen} error={error} />
      </Layout>
    </>
  );
};
