import { useEffect, useRef, useState } from 'react';

const useOutsideClick = (
  initialValue: boolean,
  callback?: React.Dispatch<React.SetStateAction<boolean>>,
  classNameSelector?: string
) => {
  const [isActive, setIsActive] = useState(initialValue);
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = (e: globalThis.MouseEvent) => {
    const target = e.target as Node;

    if (ref.current && !ref.current.contains(target)) {
      setIsActive(false);
      //@ts-ignore
      const classList = Array.from(target.classList);
      //@ts-ignore
      const parentClassList = Array.from(target?.parentNode?.classList || []);

      if (
        classNameSelector &&
        (classList.includes(classNameSelector) || parentClassList?.includes(classNameSelector))
      ) {
      } else {
        callback?.(false);
      }
    } else {
      setIsActive(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return { ref, isActive, setIsActive };
};
export default useOutsideClick;
