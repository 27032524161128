import { GridColDef } from '@mui/x-data-grid';
import { TTransaction } from 'shared/services/types/transaction.types';

export const COLUMNS_T_ALL: GridColDef<TTransaction.TransactionData[number]>[] = [
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'account_id',
    headerName: 'Account Number',
  },
  {
    field: 'subagent_id',
    headerName: 'Subagent',
  },
  {
    field: 'account_name',
    headerName: 'Account Name',
  },
  {
    field: 'remarks',
    headerName: 'Remarks',
  },
  {
    field: 'income',
    headerName: 'Income',
  },
  {
    field: 'balance',
    headerName: 'Balance',
  },
  {
    field: 'timestamp',
    headerName: 'Timestamp',
  },
];
