import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: { main: '#185AC2' },
    secondary: { main: '#001944' },
    success: { main: '#1EBD8D', dark: '#0F8E68' },
    error: { main: '#BA1A1A' },
    text: {
      primary: '#1B1B1F',
      secondary: '#90939B',
    },
    background: {
      default: '#E2EAF7',
      paper: '#fff',
    },
    divider: '#E9ECF5',
  },
  typography: {
    fontFamily: ['Roboto'].join(','),
  },
  spacing: 2,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
          textTransform: 'none',
          fontWeight: '400',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          color: '#A4B1EA',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '.MuiTableCell-root': {
            backgroundColor: '#D9E2FF',
            color: '#90939B',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #BDC1D2',
          paddingTop: '3px',
          paddingBottom: '3px',
          height: '48px',
          color: '#44464F',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          minWidth: 24,
          minHeight: 24,
          maxHeight: 24,
          borderRadius: 2,
          margin: '0 .1vw',
          color: '#44464F',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          minWidth: 10,
          height: 10,
          borderRadius: '50%',
          transform: 'scale(1) translate(2px, -2px)',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
          lineHeight: '16px',
          color: '#44464F',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#BA1A1A',
          bottom: '-28px',
          left: 'calc(50% - 40px)',
        },
      },
    },
  },
});
