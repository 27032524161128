import { useMutation } from '@tanstack/react-query';
import { AuthService } from 'shared/services/auth/auth.service';
import { TAuth } from 'shared/services/types/auth.types';

export const useActionAuth = () => {
  const loginUser = useMutation({
    mutationFn: (data: TAuth.Login) => AuthService.login(data),
    mutationKey: ['login user'],
    onSuccess: (response) => {
      localStorage.setItem('user', JSON.stringify(response.data.user));
    },
  });

  return { loginUser };
};
